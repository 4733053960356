<template>
  <div>
    <h1>Detail Teacher</h1>
    <a-row :gutter="[32, 16]">
      <a-col :md="24" :lg="14">
        <div class="card-full">
          <div class="detail">
            <a-menu class="mt-3" v-model="current" mode="horizontal">
              <a-menu-item key="user">TEACHER INFORMATION</a-menu-item>
              <a-menu-item key="edit">EDIT PROFILE</a-menu-item>
            </a-menu>

            <div v-if="current[0] === 'user'" class="mt-5">
              <a-row :gutter="[32, 32]" class="ml-2">
                <a-col :sm="12" :md="8">
                  <h3 class="type">Nama</h3>
                  <p class="value">{{teacher.nama ? teacher.nama : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">NIK</h3>
                  <p class="value">{{teacher.NIK ? teacher.NIK : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Jenis Kelamin</h3>
                  <p class="value">{{teacher.jenis_kelamin ? teacher.jenis_kelamin : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Email</h3>
                  <p class="value">{{teacher.email ? teacher.email : '-'}}</p>
                </a-col>
                <a-col :sm="12" :md="8">
                  <h3 class="type">Alamat</h3>
                  <p class="value">{{teacher.alamat ? teacher.alamat : '-' }}</p>
                </a-col>
              </a-row>
            </div>
            <div v-else class="mt-4">
              <a-form @submit.prevent="updateProfile">
                <a-form-item label="NIK">
                  <a-input v-model="teacher.NIK" style="height: 48px" />
                </a-form-item>
                <a-form-item label="Nama">
                  <a-input v-model="teacher.nama" style="height: 48px" />
                </a-form-item>
                <a-form-item label="Jenis Kelamin">
                  <a-radio-group v-model="teacher.jenis_kelamin">
                    <a-radio value="Laki-Laki">Laki-Laki</a-radio>
                    <a-radio value="Perempuan">Perempuan</a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="Email">
                  <a-input v-model="teacher.email" style="height: 48px" />
                </a-form-item>
                <a-form-item label="Alamat">
                  <a-input v-model="teacher.alamat" type="textarea" style="height: 48px" />
                </a-form-item>
                <a-button
                  class="btnChange"
                  type="primary"
                  html-type="submit"
                  size="large"
                  :loading="loadingUpdateProfile"
                  block
                >Update Now</a-button>
              </a-form>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :md="24" :lg="10">
        <div class="card-full">
          <div class="detail">
            <div class="cui__utils__heading mb-0">
              <strong>Change Password</strong>
            </div>
            <div class="text-muted mb-3">You can change teacher password here</div>
            <hr />
            <a-form @submit.prevent="updatePassword">
              <a-form-item label="New Password">
                <a-input
                  v-model="newPassword"
                  type="password"
                  autocomplete="new-password"
                  style="height: 48px"
                />
              </a-form-item>
              <a-form-item label="Verify New Password">
                <a-input
                  v-model="newVerifyPassword"
                  type="password"
                  autocomplete="verify-password"
                  style="height: 48px"
                />
              </a-form-item>
              <a-button
                class="btnChange"
                type="primary"
                html-type="submit"
                size="large"
                block
                :loading="loadingUpdatePassword"
              >Update Password</a-button>
            </a-form>
          </div>
        </div>
        <div class="card-full">
          <div class="detail">
            <div class="cui__utils__heading mb-0">
              <strong>Delete this Account</strong>
            </div>
            <div class="text-muted mb-3">You can delete teacher account here</div>
            <hr />
            <a-button
              class="btnDelete"
              type="danger"
              html-type="submit"
              size="large"
              block
              :loading="loadingDeleteTeacher"
              @click.prevent="deleteTeacher"
            >Delete Now</a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: ['user'],
      teacher: {},
      loadingUpdateProfile: false,
      loadingUpdatePassword: false,
      loadingDeleteTeacher: false,
      newPassword: '',
      newVerifyPassword: '',
    }
  },
  methods: {
    updateProfile() {
      this.loadingUpdateProfile = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to update {this.teacher.nama} profile's ?</div>
        ),
        onOk: () => {
          this.loadingUpdateProfile = false
          this.$store.dispatch('admin/UPDATE_TEACHER', { data: this.teacher })
            .then(res => {
              if (res === 200) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    `${this.teacher.nama} profile's has been updated`,
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description:
                    `${this.teacher.nama} profile's has not been updated`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingUpdateProfile = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Update',
      })
    },
    updatePassword() {
      this.loadingUpdatePassword = true
      if (this.newPassword && this.newVerifyPassword) {
        if (this.newPassword.length >= 6 && this.newVerifyPassword.length >= 6) {
          if (this.newPassword === this.newVerifyPassword) {
            this.$confirm({
              title: 'Warning',
              content: (
                <div>Are you sure to change {this.teacher.nama} password ?</div>
              ),
              onOk: () => {
                this.loadingUpdatePassword = false
                this.$store.dispatch('admin/UPDATE_TEACHER_PASSWORD', { id: this.teacher.id, data: this.newPassword })
                  .then(res => {
                    if (res === 200) {
                      this.$notification.success({
                        message: 'Success',
                        description:
                          `New password for ${this.teacher.nama} has been changed`,
                      })
                    } else {
                      this.$notification.error({
                        message: 'Error',
                        description:
                          'New password has not been changed',
                      })
                    }
                  })
                this.newPassword = ''
                this.newVerifyPassword = ''
              },
              onCancel: () => {
                this.loadingUpdatePassword = false
              },
              centered: true,
              icon: 'warning',
              okType: 'primary',
              okText: 'Change',
            })
          } else {
            this.loadingUpdatePassword = false
            this.$notification.error({
              message: 'Error',
              description:
                'New password should be the same',
            })
          }
        } else {
          this.loadingUpdatePassword = false
          this.$notification.error({
            message: 'Error',
            description:
              'New password too short, minimum 6 characters',
          })
        }
      } else {
        this.loadingUpdatePassword = false
        this.$notification.error({
          message: 'Error',
          description:
            'You must fill new password',
        })
      }
    },
    deleteTeacher() {
      this.loadingDeleteTeacher = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete {this.teacher.nama} account ?</div>
        ),
        onOk: () => {
          this.loadingDeleteTeacher = false
          this.$store.dispatch('admin/DELETE_TEACHER', { id: this.teacher.id })
            .then(res => {
              if (res === 200) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    `${this.teacher.nama} account has been deleted`,
                })
                this.$router.push({ name: 'Teachers Admin' })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description:
                    `${this.teacher.nama} account has not been deleted`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteTeacher = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
  },
  created() {
    const id = this.$route.params.id
    this.$store.dispatch('admin/FETCH_TEACHERS_BY_ID', { id })
      .then(data => {
        this.teacher = data
      })
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}

.detail .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}

.btnDelete {
  background-color: #f56a79;
  border-color: #f56a79;
  height: 48px;
}
</style>
